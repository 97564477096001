<template>
  <div class="world login">
    <div class="login-wrapper">
      <div class="title">
        <h1>{{ $t('Voer je nieuwe wachtwoord in') }}</h1>
        <div class="intro">
          <p>{{ $t('Voer hieronder je nieuwe wachtwoord in en klik op "Bewaren". Je zal dan automatisch ingelogd worden.') }}</p>
        </div>
      </div>

      <formulate-form v-model="formValues" @submit="passwordResetClaim">
        <formulate-input validation="required" error-behavior="submit" type="password" name="password" validation-name="Wachtwoord" :placeholder="$t('Wachtwoord')"></formulate-input>
        <formulate-input validation="required|confirm" error-behavior="submit" type="password" name="password_confirm" validation-name="Wachtwoord" :placeholder="$t('Wachtwoord')"></formulate-input>
        <formulate-input type="submit" :label="isLoading ? $t('Even geduld...') : $t('Bewaren')"></formulate-input>
      </formulate-form>
    </div>

    <modal class="modal" name="resetModal" :clickToClose="false" :width="770" height="auto" :adaptive="true" :scrollable="true">
      <div class="p-4">
        {{ $t('De link om je wachtwoord aan te maken is verlopen. Vraag') }}
        <RouterLink class="font-700 text-wilmsBlue" :to="{ name: 'ww.passwordreset' }">{{ $t('hier') }}</RouterLink>
        {{ $t(' een nieuw wachtwoord aan. Na het invullen van je mailadres ontvang je een nieuwe e-mail met een geldige link. Bedankt!') }}
      </div>
    </modal>
  </div>
</template>

<script>
import { notification } from '@/notifications'
import { ApiService } from '@/services/admin/api.service'

export default {
  async mounted () {
    await this.passwordResetClaimCheck()
  },
  data () {
    return {
      isLoading: false,
      formValues: {},
      message: null,
      messageType: null
    }
  },
  methods: {
    passwordResetClaimCheck: async function (data) {
      const res = await ApiService.resetPasswordCheck(this.$route.query.hash)

      if (res.status === 200) {
        this.isLoading = false
      } else {
        this.$modal.show('resetModal')
      }
    },
    passwordResetClaim: async function (data) {
      await this.$store.dispatch('resetPassword', {
        hash: this.$route.query.hash,
        password: data.password,
        passwordConfirm: data.password_confirm
      })

      if (this.$store.getters.loggedIn) {
        notification(this.$t('Uw wachtwoord is succesvol gewijzigd!'))
        await this.$router.push({ name: 'ww.dashboard' })
        this.isLoading = false
      }
    }
  }
}
</script>
